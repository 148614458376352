.footer {
  background-color: #c7eae4;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  height: 120px; /* this plus margin needs to match padding of app class in app.css*/
  margin-bottom: 30px;
  justify-content: space-evenly;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: space-evenly;
    text-align: center;
  }

  .nav-menu {
    justify-content: center;
    flex-wrap: nowrap;
  }

  .nav-item {
    font-size: 14px;
    justify-content: center;
    letter-spacing: 1px;
    margin-bottom: 0;

    a {
      line-height: 2;

      &::after {
        content: none;
      }
    }
  }
}

.contact-infos {
  font-size: 12px;

  .contact-info {
    text-align: center;
    line-height: 1.5;

    @media (min-width: 768px) {
      display: inline;
      margin: 0 20px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
