.header {
  background-color: #c7eae4;
}
.header-content {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      padding: 10px 15px;
    }
  }
}
.desktop-nav {
  display: none;
  width: 100%;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
}
.mobile-header {
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
}
