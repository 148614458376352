.fieldset-name-content {
  display: flex;
  justify-content: space-between;
}
.product-name,
.product-image {
  margin: 10px 0;
}
.product-image {
  width: 100%;
  max-width: 400px;
}

.product-details {
  display: grid;
  grid-template-columns: 7fr 1fr;
  column-gap: 20px;
  align-items: center;
}
.product-selection {
  max-width: 400px;
  margin: 20px auto;
}
@media (min-width: 768px) {
  .product-selection {
    max-width: none;
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }
}
