.flavor-lists-wrapper {
  display: flex;
  justify-content: space-around;

  .page-section-header {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.flavor-badge {
  font-weight: bold;
  font-size: 11px;
  vertical-align: super;
  margin: 0 5px;
  color: darkred;
}
.spicy-badge {
  font-weight: bold;
  color: #ff0000;
  font-size: 11px;
  vertical-align: super;
  margin: 0 5px;
}
