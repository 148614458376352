.mobile-nav {
    position: absolute;
    background-color: #fbfdfd;
    width: 100%;
    height: 100%;
    z-index: 2;
    top:0;
    
  @media (min-width: 768px) {
      display: none;
  }
}