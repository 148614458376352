.collection-page {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 38px;
    margin: 0 auto 30px;
  }

  .items {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
    justify-items: center;
    column-gap: 16px;
    grid-row-gap: 32px;
    row-gap: 32px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
