@media (min-width: 768px) {
  ._wPDyp {
    flex: 0 0 calc(100%/4 - 10px);
  }
}
._vzTHL {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}
._3xnQP {
  z-index: 1;
}