$burger-color: rgba(43, 43, 43, 1);
$burger-line-height: 3px;

.burger-menu {
  background: none;
  border: none;
  cursor: pointer;
  height: 33px;
  position: relative;
  width: 33px;
  margin: auto 0;

  @media (min-width: 768px) {
    display: none;
  }
}
.burger {
  position: absolute;
  background: $burger-color;
  width: 100%;
  height: 3px;
  top: 50%;
  right: 0px;
  margin-top: -1px;
  opacity: 1;
}
.burger::before {
  position: absolute;
  background: $burger-color;
  width: 33px;
  height: $burger-line-height;
  top: 12px;
  content: "";
  display: block;
}
.burger::after {
  position: absolute;
  background: $burger-color;
  width: 33px;
  height: $burger-line-height;
  bottom: 12px;
  content: "";
  display: block;
}
.burger::after,
.burger::before,
.burger {
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
}
.burger-menu.active .burger::after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  bottom: 0px;
}
.burger-menu.active .burger::before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 0px;
}
.burger-menu.active .burger {
  background: rgba(111, 111, 111, 0);
}
