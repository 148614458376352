.page-header {
  padding: 15px 0;
}
.has-sub-header {
  padding-bottom: 0;
}
.sub-page-header {
  text-align: center;
  font-family: "Rufina", serif;
  margin-bottom: 15px;
}
.page-header-content {
  line-height: 1.5;
  font-size: 16px;

  &.centered {
    text-align: center;
  }
}
