.collection-item {
  display: flex;
  flex-direction: column;
  height: 350px;
  max-width: 350px;
  width: 100%;
  align-items: center;
  position: relative;

  .image {
    width: 100%;
    height: 95%;
    background-size: cover;
    background-position: center;
    margin-bottom: 5px;
  }

  .custom-button {
    width: 80%;
    // opacity: 0.7;
    position: absolute;
    top: 255px;
    opacity: 0;
    
    &:focus {
      opacity: 1;
    }
  }

  &:hover, &.adding {
    .image {
      opacity: 0.8;
    }

    .custom-button {
      display: flex;
      opacity: 1;
    }
  }

  .collection-footer {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;

    .name {
      width: 90%;
      margin-bottom: 15px;
    }

    .price {
      width: 20%;
      text-align: right;
    }
  }
}
.collection-item-wrapper {
  max-width: 350px;
  width: 100%;

  .description {
    margin-top: 10px;
    font-size: 14px;
  }
}
