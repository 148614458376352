.checkout-item {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr .5fr;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 14px;
  align-items: center;
        
  @media (min-width: 768px) {
    font-size: 20px;
  }

  .image-container {
    padding-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }

 

  .quantity {
    display: flex;
    align-items: center;

    .arrow {
      cursor: pointer;
      font-size: 20px;
    }

    .value {
      margin: 0 10px;
    }
  }

  .remove-button {
    padding-left: 12px;
    cursor: pointer;
    min-width: 43px;
  }
}
