.custom-link-button {
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: #2b2b2b;
  color: white;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  display: inline-block;

  &:hover {
    background-color: #3d3d3d;
  }
  &.google-sign-in {
    background-color: #4285f4;
    color: white;

    &:hover {
      background-color: #357ae8;
      border: none;
    }
  }
}

.custom-link-button-container.cloud {
  margin-top: 27px;
  position: relative;
  padding-left: 2px;
  height: 52px;
  transition: background-color 300ms ease-out;
  background-color: transparent;
  border-radius: 100px;


  .custom-link-button {
    background: #c7eae4;
    border-radius: 100px;
    box-shadow: 3px 6px 6px 0px rgba(0, 0, 0, 0.2);
    display: block;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    position: relative;
    text-align: center;
    width: 120px;
    min-width: auto;
    padding: 0;
    color: #2b2b2b;

    &:before,
    &:after {
      z-index: -9999;
      position: absolute;
      background: #c7eae4;
      content: "";
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      border-radius: 100px;
    }

    &:before {
      top: -11px;
      left: 19px;
      width: 40px;
      height: 40px;
    }

    &:after {
      top: -15px;
      right: 25px;
      width: 50px;
      height: 50px;
    }
  }
}
