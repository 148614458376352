.conclusion {
  margin-top: 35px;
}
.about-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 768px) {
    flex-direction: row;
  }

  .about-image {
    margin: 0 15px 15px;
    width: 50%;
    
    @media (min-width: 768px) {
      width: 35%;
    }
  }
}
