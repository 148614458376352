.cart-item {
  width: 100%;
  display: grid;
  height: 80px;
  margin-bottom: 15px;
  grid-template-columns: 1fr 2fr;

  img {
    width: 100%;
  }

  .item-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 20px;

    .name {
      font-size: 16px;
    }
  }
}
