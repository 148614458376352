.collection-preview {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .title {
    font-size: 20px;
    margin-bottom: 25px;
    text-align: left;
  }

  .preview {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
    justify-items: center;
    column-gap: 16px;
    grid-row-gap: 32px;
    row-gap: 32px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
