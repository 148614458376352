.logo {
  height: 100%;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    margin-right: 30px;
  }

  .logo-name {
    font-family: "Comfortaa";
    text-align: center;
  }

  .logo-name-top {
    font-size: 30px;
    border-bottom: 2px solid #2b2b2b;
    margin-bottom: 3px;
  }

  .logo-name-bottom {
    font-size: 18px;
  }

  .logo-image-icon {
    width: 65px;
    padding: 0 5px;
  }
}
