/* apply a natural box layout model to all elements, but allowing components to change */
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  background-color: #fbfdfd;
  color: #2b2b2b;
}

a {
  text-decoration: none;
  color: #2b2b2b;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

li {
  list-style-position: inside;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.company-name {
  font-family: "Comfortaa", cursive;
  font-weight: bold;
}

.content-container {
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .content-container {
    padding: 0 10px;
  }
}

.inline-link {
  text-decoration: underline;
}

#root {
  position: relative;
  min-height: 100vh;
}

.App {
  padding-bottom: 150px; /*height of the footer*/
}
.main-content {
  margin-bottom: 40px;
}

h1,
h2, h3 {
  /* font-family: "Emilys Candy"; */
  text-align: center;
  font-family: "Rufina", serif;
  font-weight: normal;
}

button {
  border: 0;
  background: none;
  cursor: pointer;
}