.navigation-list {
  height: 100%;
  width: 100%;

  .mobile-nav & {
    height: auto;
    padding-top: 80px;
  }
}
.nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-wrap: wrap;

  .mobile-nav & {
    flex-direction: column;
    justify-content: center;
    transition: all 300ms ease-out;
    opacity: 1;
    &.fade-appear {
      opacity: 0;
      padding-left: 100px;
    }
  }

  li {
    list-style: none;
  }
}
.nav-item {
  display: inline-block;
  font-size: 20px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  padding: 0 5px;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 14px;
    margin: 0;

    .active {
      display: inline-block;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        right: 2px;
        height: 2px;
        background: #2b2b2b;
      }
    }
  }
}
