.homepage-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px 30px;

  @media (min-width: 768px) {
    width: 33.33%;
  }
}
.homepage-service-image {
  width: 90%;
  margin-bottom: 15px;
  max-width: 320px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}
.homepage-service-header {
  margin: 15px 0;
}
.homepage-service-content {
  line-height: 1.5;
  font-size: 15px;
}
