.service-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  max-width: 325px;

  &:first-child {
    margin-top: 30px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: starta;
    max-width: none;
    width: 100%;
  }
}
.service-service-image {
  max-width: 60%;
  height: auto;
  border-radius: 200px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);

  @media (min-width: 768px) {
    width: 30%;
    align-self: center;
  }
}
.service-service-header {
  margin-top: 15px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.service-service-content {
  line-height: 1.5;
  font-size: 15px;
  width: 100%;

  @media (min-width: 768px) {
    width: 60%;
    padding-left: 30px;
  }
}
.service-service-inclusions {
  margin-top: 10px;
}

.service-price {
  font-size: 18px;
  font-family: "Lato", sans-serif;
}
.scratch-price {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  text-decoration: line-through;
  color: #7e7b7b;
  margin-right: 2px;
}

.service-service-inclusions-wrapper {
  .custom-link-button-container {
    margin-top: 15px;
    text-align: center;
  }
    display: flex;
    align-items: center;
    justify-content: space-between;
}

