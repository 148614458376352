@keyframes scale {
  0% {
    transform: scale3d(0.5, 0.5, 1);
    opacity: 0;
  }
  70% {
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
    opactiy: 100;
  }
}
.checkmark {
  padding-top: 5px;
  display: flex;
  transform: scale3d(0.5, 0.5, 1);
  animation: opacity 0.5s, scale 1s ease-in-out 0s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
.checkmark--check {
  stroke: #2b2b2b;
  stroke-width: 25;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: stroke 1s cubic-bezier(0.650, 0.000, 0.450, 1.000) 1s forwards;
}
