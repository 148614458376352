.checkout-page {
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto 0;

  .checkout-header {
    width: 100%;
    padding: 10px 0;
    display: grid;
    border-bottom: 1px solid darkgrey;
    grid-template-columns: 1fr 2fr 1fr 1fr 0.5fr;

    .header-block {
      text-transform: capitalize;
      font-size: 12px;

      @media (min-width: 768px) {
        font-size: 20px;
      }

      &:last-child {
        min-width: 43px;
      }
    }
  }

  .checkout-footer {
    width: 100%;

    .custom-button {
      margin: 0 auto;
      display: flex;
      align-items: center;
      &:disabled {
        background-color: #5c5c5c;
        .pay-now-text {
          padding-right: 20px;
        }
      }
    }
    .pay-now-text {
      display: inline-block;
    }
  }
  .empty-message {
    margin: 30px;
  }
  .total {
    margin-top: 30px;
    margin-right: 10px;
    text-align: right;
    font-size: 20px;
  }
}
