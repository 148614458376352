.cart-dropdown {
  position: absolute;
  width: 300px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 20px;
  border: 1px solid black;
  background-color: white;
  top: 90px;
  right: 20px;
  z-index: 5;

  .empty-message {
    font-size: 18px;
    margin: 50px auto;
  }

  .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  button {
    margin-top: auto;
  }

  .cart-close-icon {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 20px;
  }
}
