.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offering-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;

  .product-image {
    max-height: 350px;
  }
}

.offering-cta {
  margin-bottom: 40px;
  text-align: center;
}
